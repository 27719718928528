<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-tabs
        v-model="tab"
        no-caps
        class="bg-primary text-white"
        indicator-color="orange"
      >
        <q-tab name="siswa" label="PER-SISWA" />
        <q-tab name="tanggal" label="PER-TANGGAL" />
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="siswa" class="q-pa-none">
          <q-card-section class="q-gutter-md">
            <q-select
              outlined
              v-if="user.is_super_user == '1'"
              v-model="selMusyrif"
              label="Musyrif"
              :options="musyrif"
              @input="
                (selSiswa = null),
                  (tanggal = []),
                  (siswa = []),
                  (selKegiatanPerSiswa = null),
                  getListSantri()
              "
            />
            <q-select
              outlined
              v-model="selKegiatanPerSiswa"
              label="Kegiatan"
              :options="kegiatan"
              @input="
                (selSiswa = null), (tanggal = []), (siswa = []), getListSantri()
              "
            />

            <q-select
              outlined
              :disable="selKegiatanPerSiswa == null"
              v-model="selSiswa"
              label="Siswa"
              :options="siswa"
              @input="(tanggal = []), getListTanggal()"
            />
          </q-card-section>
          <q-markup-table
            flat
            dense
            wrap-cells
            separator="horizontal"
            class="stickyTable q-mt-lg no-border-radius bg-grey-4"
            style="height:calc(100vh - 298px);"
          >
            <thead class="bg-indigo-5 text-white text-left">
              <tr class="text-left">
                <th>Tanggal</th>
                <th style="max-width:100px">Keterangan</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-if="selKegiatanPerSiswa == null || selSiswa == null">
                <td class="text-center" colspan="2">
                  Silahkan Lengkapi Data di Atas
                </td>
              </tr>
              <tr v-else-if="tanggal.length == 0">
                <td class="text-center" colspan="2">Belum ada data Absen</td>
              </tr>
              <tr v-for="(val, i) in tanggal" :key="i">
                <td>{{ val.tanggal | moment("DD-MMM-YYYY") }}</td>
                <td style="min-width:100px">
                  <q-select
                    outlined
                    dense
                    v-model="val.status"
                    :options="opsiStatus"
                  />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-tab-panel>
        <q-tab-panel name="tanggal" class="q-pa-none">
          <div class="q-gutter-md q-pa-md">
            <q-select
              outlined
              v-if="user.is_super_user == '1'"
              v-model="selMusyrif"
              label="Musyrif"
              :options="musyrif"
              @input="
                (selTanggal = null),
                  (kehadiranTanggal = []),
                  (pj = '-'),
                  (selKegiatanPerTanggal = null)
              "
            />
            <q-select
              outlined
              v-model="selKegiatanPerTanggal"
              label="Kegiatan"
              :options="kegiatan"
              @input="(selTanggal = null), (kehadiranTanggal = []), (pj = '-')"
            />
            <q-input
              :disable="selKegiatanPerTanggal == null"
              outlined
              readonly
              v-model="selTanggal"
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      @input="getKehadiranList()"
                      v-model="selTanggal"
                      mask="YYYY-MM-DD"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="OK" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <q-markup-table
            flat
            dense
            wrap-cells
            separator="horizontal"
            class="stickyTable no-border-radius bg-grey-4 q-mt-lg"
            style="height:calc(100vh - 363px);"
          >
            <thead class="bg-indigo-5 text-white text-left">
              <tr class="text-left">
                <th>Siswa</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-if="selKegiatanPerTanggal == null">
                <td class="text-center" colspan="2">
                  Silahkan Lengkapi Data di Atas
                </td>
              </tr>
              <tr v-else-if="kehadiranTanggal.length == 0">
                <td class="text-center" colspan="2">
                  Belum ada data kehadiran di tanggal terpilih
                </td>
              </tr>
              <tr v-for="(val, i) in kehadiranTanggal" :key="i">
                <td>{{ val.nama }}</td>
                <td style="min-width:100px">
                  <q-select
                    outlined
                    dense
                    v-model="val.status"
                    :options="opsiStatus"
                  />
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-tab-panel>
      </q-tab-panels>

      <q-card-actions class="q-pa-none">
        <q-btn
          filled
          color="negative"
          class="full-width no-border-radius"
          @click="simpan()"
          :disable="
            (tab == 'siswa' && tanggal.length == 0) ||
              (tab == 'tanggal' && kehadiranTanggal.length == 0)
          "
          >simpan</q-btn
        >
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selTanggal: null,
      kehadiranTanggal: [],
      pj: "-",
      user: {},

      musyrif: [],
      selMusyrif: {
        value: null,
      },

      tab: "siswa",
      confirm: true,
      pekan: [1, 2, 3, 4, 5],
      selPekan: null,
      opsiStatus: ["Hadir", "Masbuq", "Haid", "Tidak Hadir"],
      selKegiatanPerSiswa: null,
      selKegiatanPerTanggal: null,
      kegiatan: [
        { label: "SHUBUH", value: 1 },
        { label: "DZUHUR", value: 2 },
        { label: "ASHAR", value: 3 },
        { label: "MAGHRIB", value: 4 },
        { label: "ISYA", value: 5 },
        { label: "TAHAJUD", value: 6 },
        { label: "TILAWAH", value: 7 },
        { label: "ALMASURAT", value: 8 },
        { label: "TASQIF", value: 9 },
        { label: "TAHFIDZ", value: 10 },
        { label: "KARANTINA TAHDIFZ", value: 11 },
        { label: "PUASA KAMIS", value: 12 },
        { label: "SHALAT RAWATIB", value: 13 },
      ],
      selSiswa: null,
      siswa: [],
      tanggal: [],
      searchTerm: "",
      listSurat: [],
    };
  },
  async mounted() {
    await this.getUser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getKehadiranList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.pj = "-";
      this.kehadiranTanggal = [];
      await this.$http
        .get(
          `/penilaian/riwayat_absen/getkamar/${this.selTanggal}/${this.selKegiatanPerTanggal.label}/${this.selMusyrif.value}`
        )
        .then((resp) => {
          resp.data.forEach((element) => {
            if (element.is_masbuq == 1) {
              element.status = "Masbuq";
            } else if (element.is_haid == 1) {
              element.status = "Haid";
            } else if (
              element.is_hadir == 1 &&
              element.is_masbuq == 0 &&
              element.is_haid == 0
            ) {
              element.status = "Hadir";
            } else {
              element.status = "Tidak Hadir";
            }
          });
          this.kehadiranTanggal = resp.data;
          if (this.kehadiranTanggal.length != 0) {
            if (this.kehadiranTanggal[0].pj != null) {
              this.pj = this.kehadiranTanggal[0].pj;
            }
          }
        });
      this.$q.loading.hide();
    },
    simpan() {
      let temp = null;
      if (this.tab == "siswa") {
        temp = JSON.parse(JSON.stringify(this.tanggal));
      } else if (this.tab == "tanggal") {
        temp = JSON.parse(JSON.stringify(this.kehadiranTanggal));
      }
      temp.forEach((element) => {
        if (element.status == "Hadir") {
          element.is_hadir = true;
          element.is_haid = false;
          element.is_masbuq = false;
        } else if (element.status == "Haid") {
          element.is_hadir = true;
          element.is_haid = true;
          element.is_masbuq = false;
        } else if (element.status == "Masbuq") {
          element.is_hadir = true;
          element.is_haid = false;
          element.is_masbuq = true;
        } else {
          element.is_hadir = false;
          element.is_haid = false;
          element.is_masbuq = false;
        }
      });
      this.$http
        .put("/penilaian/riwayat_absen/update_status/", temp)
        .then((resp) => {
          this.$q.notify({
            type: "positive",
            message: "Status Kehadiran Berhasil Dirubah",
          });
        });
    },
    /* getListPeriod() {
      //harusnya memanggil data siswa yang ada di kamar ini
      //asumsinya yg bisa mengakses ini adalah wali kelas
      this.$http.get("/penilaian/riwayat_absen/getperiod/").then(resp => {
        for (let i in resp.data) {
          resp.data[i].label =
            resp.data[i].tahun + " - Semester " + resp.data[i].semester;
          resp.data[i].value = i;
        }
        this.period = resp.data;
      });
    }, */
    getListSantri() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get(`/penilaian/riwayat_absen/getsantri/${this.selMusyrif.value}`)
        .then((resp) => {
          this.siswa = resp.data;
        });
      this.$q.loading.hide();
    },
    getListTanggal() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get(
          `/penilaian/riwayat_absen/gettgl/${
            this.selSiswa.value
          }/${localStorage.getItem("id_tahun_ajaran")}/${
            this.selKegiatanPerSiswa.label
          }/`
        )
        .then((resp) => {
          resp.data.forEach((element) => {
            if (element.is_masbuq == 1) {
              element.status = "Masbuq";
            } else if (element.is_haid == 1) {
              element.status = "Haid";
            } else if (
              element.is_hadir == 1 &&
              element.is_masbuq == 0 &&
              element.is_haid == 0
            ) {
              element.status = "Hadir";
            } else {
              element.status = "Tidak Hadir";
            }
          });
          this.tanggal = resp.data;
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
